<template>
  <b-overlay :show="show" rounded="sm">
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <b-card-header class="d-flex justify-content-between align-items-center">
          <b-card-title class="mb-0">User Details</b-card-title>
          <div class="user-phone ml-3" >{{   profiles[0].number }}</div>
          <div class="ml-3 d-flex align-items-center custom-checkbox-align">
        <label for="app-access" class="mr-2 mb-0">App access</label>
        <b-form-checkbox
          v-model="appAccess.appAccess"
          v-on:change="confirmStatusChange"
          switch
          size="lg"
        ></b-form-checkbox>
      </div>
        </b-card-header>
      <user-dropdown style="width: 25%;" :options="allUsers" :selected-option="selectedUser" labelValue="Search User" placeholder="" @getUserDropDown="userDropdown"/>
      </div>
      <b-row>
        <b-col cols="6" md="2" v-for="user in profiles"  class="profile-item" :key="user.id"  :class="{ 'highlighted-user': user._id.$oid === selectedUserId }" @click="redirectToProfile(user._id.$oid)">
          <div class="d-flex flex-column align-items-center justify-content-center"> 
            <div>
            <img :src="'https://zape02.s3.ap-southeast-1.amazonaws.com/' + user.avatar" style="width: 100px; height: 90px" alt="">
          </div>
          <div>
              <b>Name :</b> <span>{{ user.name }}</span>
              <br>
              <b>Gender :</b> <span>{{ user.gender }}</span>
              <br>
              <b>Time :</b> <span>{{ user.formatted_time }}</span>
              <br>
              <b>Date :</b> <span>{{ formatDate(user.createdAt) }}</span>

          </div>
              </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card v-if="table_data" no-body class="card-statistics">
        <b-card-header>
          <b-card-title>Grade Statistics</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col v-for="(item, index) in table_data" :key="item.icon" xl="2" sm="2" style="margin: 10px">
              <b-media no-body :class="` ${selectedIndex === index ? 'border-color' : 'border-blue'
                }`" @click="selectSubjectClass(index)" style="cursor: pointer">
                <b-media-aside class="mr-2">
                  <b-avatar size="48" class="b-avatar badge-light-primary rounded-circle" variant="primary">
                    <img :src="item.icon" style="width: 48px; height: 48px; object-fit: contain" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.data }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0" style="color: black" v-if="item.name != 'Total Time'">
                    {{ item.name }}
                  </b-card-text>
                  <b-card-text class="font-small-3 mb-0" v-else>
                    Total Minutes
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-card>
    <b-card no-body>
      <b-card v-if="table_data " no-body class="card-statistics">
        <b-card-header>
          <b-card-title>Subject Statistics</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            <!-- Updated 1 month ago -->
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col v-for="(item, ind) in subjectArr" :key="item.icon" xl="2" sm="2" style="margin: 10px">
              <b-media no-body :class="` ${subjectIndex === ind ? 'border-color' : 'border-blue'
                }`" @click="selectSubject(ind)" style="cursor: pointer">
                <b-media-aside class="mr-2">
                  <b-avatar size="48" class="b-avatar badge-light-primary rounded-circle" variant="primary">
                    <img :src="item.icon" style="width: 48px; height: 48px; object-fit: contain" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.data + "%" }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0" v-if="item.name != 'Total Time'">
                    {{ item.name }}
                  </b-card-text>
                  <b-card-text class="font-small-3 mb-0" v-else>
                    Total Minutes
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-card>
    <b-card no-body>
      <b-card v-if="table_data" no-body class="card-statistics">
        <b-card-header>
          <b-card-title>Category Statistics</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            <!-- Updated 1 month ago -->
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col v-for="(item, ind) in categoryArr" :key="item.icon" xl="2" sm="2" style="margin: 10px">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" class="b-avatar badge-light-primary rounded-circle" variant="primary">
                    <img :src="item.icon" style="width: 48px; height: 48px; object-fit: contain" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ Math.round(item.progress) + "%" }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0" v-if="item.name != 'Total Time'">
                    {{ item.name }}
                  </b-card-text>
                  <b-card-text class="font-small-3 mb-0" v-else>
                    Total Minutes
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
        <b-modal title="Provide Description" centered v-model="showdiscriptionModal" @cancel.prevent="cancelModal" @ok.prevent="submitDescription" @close.prevent="cancelModal">
          <b-form-group label="Please enter a description (at least 50 characters):">
            <b-form-textarea v-model="description" rows="6"></b-form-textarea>
          </b-form-group>
          <div class="alert alert-danger" v-if="descriptionError">
            Please enter at least 50 characters.
          </div>
          <div>
      <span>{{ description.length }}</span> / 50 characters
    </div>  
        </b-modal>
      </b-card>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BFormCheckbox,
  BFormTextarea,
  BFormGroup,
  BAlert,
} from "bootstrap-vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import "sweetalert2/src/sweetalert2.scss";
import UserDropdown from "../views/components/UserDropdown.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BAlert,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    UserDropdown,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  computed: {
  },
  data() {
    return {
      table_data: {},
      appAccess : {},
      user:[],
      selectedUser:0,
      selectedProfile:0,
      selectedUserId: this.$route.params.AppuserId,
      profiles : [],
      allUsers:[],
      searchQuery: "",
      assignUserId: {
        id: "",
      },
      routedata: this.$route.params.AppuserId,

      newObjectRouteIndex: {
        id: "",
      },
      selectedIndex: 0,
      subjectArr: [],
      categoryArr : [],
      subjectIndex:0,
      show:false,
      notificationMessage:"",
      description: '',
      descriptionError: false,
      toastMsg: '',
      showdiscriptionModal:false
    };
  },
  
  created() {
    this.gettabledata();
  },
  methods: {
    confirmStatusChange() {
    this.showdiscriptionModal = true;
  },
  cancelModal() {
    this.showdiscriptionModal = false;
    this.appAccess.appAccess =!this.appAccess.appAccess;  
    this.descriptionError = false; 
    this.description = ''; 

  },
  submitDescription() {
    if (this.description.length < 50) {
      this.descriptionError = true;
    } else {
      this.descriptionError = false;
      const payload = {
        ...this.appAccess,
        description: this.description
      };
      this.statusChangeAppAccess(payload);   
      this.showdiscriptionModal = false;
      this.description = ''
    }
  },
    formatDate(dateObj) {
      if (dateObj && dateObj['$date'] && dateObj['$date']['$numberLong']) {
        const timestamp = parseInt(dateObj['$date']['$numberLong']);
        const date = new Date(timestamp);
        return date.toLocaleDateString();
      } else {
        return '-';
      }
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.notificationMessage,
          variant,
        },
      });
    },
    userDropdown(data){
      this.$router.push('/user-report/' + data._id.$oid);
      location.reload();
    },
    redirectToProfile(userId) {
      if (userId === this.selectedUserId) {
        return;
      }
      this.$router.push('/user-report/' + userId);
      location.reload();
    },
    selectSubjectClass(index) {
      this.selectedIndex = index;
      this.subjectArr = this.table_data[index].subject;
      this.selectSubject(0);
    },
    selectSubject(index){
      this.subjectIndex = index
      this.categoryArr = this.subjectArr[index].category;
    },
    statusChangeAppAccess(object) {
      this.show = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/app-user/app-access/status", object, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data.status) {
            this.notificationMessage = get_response.data.message;
            this.showToasted("success");
            this.gettabledata();
            this.show = false;
          } else {
            this.notificationMessage = "There are some problem try again";
            this.showToasted("danger");
            this.show = false;
          }
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    gettabledata: function () {
      this.show = true;
      this.newObjectRouteIndex.id = this.routedata;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/app-users/get/user-data",
          this.newObjectRouteIndex,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.table_data = response.data.finalClassProgress;
          this.subjectArr = this.table_data[this.selectedIndex].subject;
          this.categoryArr = this.subjectArr[this.subjectIndex].category;
          this.profiles = response.data.screens;
          this.appAccess = response.data.appAccess;
          this.user = response.data.user;
          this.allUsers = response.data.appUsers.reverse();
          this.show = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.border-color {
  background: #6666;
  border-radius: 40px;
  border: 1px solid #666666;
}
.profile-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-item:hover {
  background-color: #f0f0f0;
}

.highlighted-user {
  background-color: #ffeeba;
  border: 1px solid #ffc107;
}
.user-phone {
  font-size: 1em;
  font-weight: normal;
  color: #555;
}
.avatar {
  width: 100px;
  height: 90px;
  border-radius: 50%;
}

.profile-item div {
  margin-bottom: 5px;
}
.vs__open-indicator {
  fill: #9d9d9d;
}
.custom-checkbox-align {
  display: flex;
  align-items: center;
}

.custom-checkbox-align span {
  display: inline-flex;
  align-items: center;
}

.custom-checkbox-align .custom-control-input {
  position: relative;
  margin-bottom:18px;
}
</style>

